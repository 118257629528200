<template>
  <section>
    <v-row>
      <v-col cols="6">
        <p class="text-h6 primary--text my-0">Solicitud de compra por convenio Marco</p>
        <p class="my-0">Unidad técnico humano</p>
        <p class="my-0">Ejercicio 2022</p>
      </v-col>
      <v-col class="offset-xl-4 offset-lg-3 offset-md-2">
        <v-btn class="secondary ma-2" dark small to="/solicitud-create">Agregar solicitud</v-btn>
      </v-col>
    </v-row>

    <div class="d-flex justify-center my-7">
      <v-card
          color="bgMinsal"
          elevation="0"
          v-for="item, i in estadosSolicitud"
          :key="i"
          class="mx-2"
        >
          <v-card-title class="justify-center py-0 mt-5 mb-0">
            <p class="text-h5 mb-3">{{ item.cantidad }}</p>
          </v-card-title>
          <v-card-subtitle class="justify-center my-0 py-0">
            <p class="my-0 py-0">{{ item.texto }}</p>
          </v-card-subtitle>
          <v-progress-linear
            :color="item.color"
            rounded
            value="100"
            class="my-0 py-0"
          ></v-progress-linear>
        </v-card>
    </div>
    <tabla-solicitudes />
  </section>
</template>

<script>

import TablaSolicitudes from "./components/TablaSolicitudesComponent.vue";
export default {
  name: "aprobacionSolicitudView",
  components: { TablaSolicitudes },
  data() {
    return {
      estadosSolicitud: []
    }
  },

  created() {
    this.estadosSolicitud = [
        {
            texto: 'Aprobados',
            cantidad: '1',
            color: 'green accent-3'
        },
        {
            texto: 'Pendiente',
            cantidad: '0',
            color: '#111C4E'
        },
        {
            texto: 'Con observación',
            cantidad: '1',
            color: '#FFEE58'
        },
    ]
  }
}
</script>