<template>
  <div>
    <p class="primary-text">Listado de solicitudes</p>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          v-model="unidad"
          outlined
          :items="unidades"
          item-text="nombre"
          item-value="id"
          no-data-text="No hay datos para mostrar"
          dense
          label="Unidades"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-select
          outlined
          auto-select-first
          :items="tiposCompra"
          item-text="nombre"
          item-value="id"
          no-data-text="No hay datos para mostrar"
          dense
          label="Tipo de compra"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-menu
          ref="menuFechaDesde"
          v-model="menuFechaDesde"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaFormateada.desde"
              label="Desde"
              prepend-icon="mdi-calendar"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              @change="digitarFecha(fechaFormateada.desde, 'desde')"
              v-mask="'##/##/####'"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha.desde"
            no-title
            scrollable
            :max="maxFechaDesde"
            @input="menuFechaDesde = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu
          ref="menuFechaHasta"
          v-model="menuFechaHasta"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaFormateada.hasta"
              label="Hasta"
              prepend-icon="mdi-calendar"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              @change="digitarFecha(fechaFormateada.hasta, 'hasta')"
              v-mask="'##/##/####'"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha.hasta"
            no-title
            scrollable
            :min="maxFechaHasta"
            :max="fechaActual"
            @input="menuFechaHasta = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          auto-select-first
          :items="estados"
          item-text="nombre"
          item-value="id"
          no-data-text="No hay datos para mostrar"
          dense
          label="Estados"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="encabezados"
      :items="solicitudes"
      item-value="id"
      hide-default-footer
      no-data-text="No hay datos para mostrar"
    >
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          label
          :color="
            item.estado.id === 1
              ? '#A5D6A7'
              : item.estado.id === 2
              ? '#FFF176'
              : ''
          "
          style="width: 100%"
          class="d-flex justify-center"
        >
          {{ item.estado.nombre }}
        </v-chip>
      </template>

      <template v-slot:[`item.accion`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{
                name: 'detalle-jefe-area-aprobacion',
                params: { id: item.id },
              }"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TablaSolicitudesComponent",
  data() {
    return {
      fechaActual: this.moment().format("YYYY-MM-DD"),
      menuFechaDesde: false,
      menuFechaHasta: false,
      unidad: null,
      tipoCompra: null,
      estado: null,
      solicitudes: null,
      fecha: { desde: null, hasta: null },
      fechaFormateada: { desde: null, hasta: null },
      unidades: [],
      tiposCompra: [],
      estados: [],
      encabezados: [
        {
          text: "Correlativo",
          align: "center",
          sortable: false,
          value: "correlativo",
        },
        {
          text: "Unidad solicitante",
          align: "center",
          value: "unidadSolicitante",
        },
        {
          text: "Fecha de creación",
          align: "center",
          value: "fechaCreacion",
        },
        {
          text: "Tipo de compra",
          align: "center",
          value: "tipoCompra",
        },
        {
          text: "Estado",
          align: "center",
          value: "estado",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "center",
          value: "accion",
        },
      ],
    };
  },

  watch: {
    "fecha.desde"(val) {
      if (val === "" || val === null) return;
      this.fechaFormateada.desde = this.moment(val).format("DD/MM/YYYY");
    },

    "fecha.hasta"(val) {
      if (val === "" || val === null) return;
      this.fechaFormateada.hasta = this.moment(val).format("DD/MM/YYYY");
    },
  },

  computed: {
    ...mapState("solicitudCompraJefeArea", ["solicitudCompras"]),
    maxFechaDesde() {
      if (this.fecha.hasta === null || this.fecha.hasta === "")
        return this.fechaActual;
      return this.fecha.hasta;
    },

    maxFechaHasta() {
      if (this.fecha.desde !== null || this.fecha.desde !== "")
        return this.fecha.desde;
      return;
    },
  },

  methods: {
    digitarFecha(fecha, preposicion) {
      const formato = "DD-MM-YYYY";
      //se formatea de nuevo la fecha para cambiar / por - si no moment toma dia como mes y susesivamente
      const formatoFecha = this.moment(fecha, formato);
      const validarFecha = this.moment(formato, formato);
      if (
        this.fechaFormateada[preposicion] !== null ||
        this.fechaFormateada[preposicion] !== ""
      ) {
        if (validarFecha.isValid) {
          this.fecha[preposicion] =
            this.moment(formatoFecha).format("YYYY-MM-DD");
        } else {
          this.fechaFormateada[preposicion] = null;
          this.fecha[preposicion] = null;
        }
      }
    },
  },

  created() {
    this.unidades = [
      { id: 1, nombre: "Todas las unidades" },
      { id: 2, nombre: "Unidad 1" },
      { id: 3, nombre: "Unidad 2" },
      { id: 4, nombre: "Unidad 3" },
      { id: 5, nombre: "Unidad 4" },
    ];
    this.tiposCompra = [
      { id: 1, nombre: "Todos" },
      { id: 2, nombre: "Por proceso" },
      { id: 3, nombre: "Compra en línea" },
      { id: 4, nombre: "Convenio Marco" },
    ];
    this.estados = [
      { id: 1, nombre: "Aprobados por unidad" },
      { id: 2, nombre: "Con observación" },
      { id: 3, nombre: "Elaborada" },
    ];
    this.solicitudes = this.solicitudCompras;
  },
};
</script>